@tailwind base;
@tailwind components;
@tailwind utilities;

.chatbot-content::-webkit-scrollbar { width: 0.25rem; height: 0.25rem; }
.chatbot-content::-webkit-scrollbar-track { @apply bg-gray-200; }
.chatbot-content::-webkit-scrollbar-thumb { @apply bg-primary; }
.chatbot-content::-webkit-scrollbar-thumb:hover { @apply bg-primary; }

.chatbot {

  --background: 0 0% 100%;
  --foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;
  --ring: 215 20.2% 65.1%;
  --radius: 0.5rem;

  * {
    @apply border-border font-sans antialiased box-border border-0 border-solid leading-6;
  }
  p {
    &:last-child {
      @apply mb-0;
    }
  }
}
